ion-modal {
  --width: 100%;
  &:not(.modal-sheet) {
    --min-width: 100%;
    --min-height: 100%;
  }
  &::part(backdrop) {
    opacity: 0.25 !important;
  }

  &.rounded::part(content) {
    @apply rounded-t-lg;
  }

  &.modal-sheet {
    --height: auto;
  }
}
