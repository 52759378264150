ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
}

.custom-alert .alert-button-group {
  padding: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  width: 45%;
}

button.alert-button.alert-button-cancel {
  background-color: white;
  color: black;
  width: 45%;
  text-align: center;
}

button.alert-button.alert-button-solo {
  border-radius: 12px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  width: 100%;
  text-align: center;
}

// 안드로이드
.alert-wrapper.sc-ion-alert-md {
  border-radius: 12px;
  width: 280px;
}

// Alert Title
.alert-title.sc-ion-alert-md {
  text-align: center;
  font-size: 16px;
  line-height: 16px !important;
}

// Alert subTitle
.alert-sub-title.sc-ion-alert-md {
  align-items: center;
  text-align: center;
  font-weight: 500 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

//  Alert Message
.alert-message.sc-ion-alert-md {
  font-size: 14px;
  text-align: start;
}

.alert-head.sc-ion-alert-md + .alert-message.sc-ion-alert-md {
  text-align: center;
  padding-bottom: 0px;
}

.alert-button-group {
  padding: 12px;
}
.alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

.md button.alert-button.alert-button-confirm {
  border-radius: 12px;
  text-align: center;
}

// Ios
.alert-wrapper.sc-ion-alert-ios {
  border-radius: 12px;
  width: 280px;
}

// Alert Title
.alert-title.sc-ion-alert-ios {
  text-align: center;
  font-size: 16px;
  line-height: 16px !important;
}

// Alert subTitle
.alert-sub-title.sc-ion-alert-ios {
  align-items: center;
  text-align: center;
  font-weight: 500 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

//  Alert Message
.alert-message.sc-ion-alert-ios {
  font-size: 14px;
  text-align: start;
}

.alert-head.sc-ion-alert-ios + .alert-message.sc-ion-alert-ios {
  text-align: center;
  padding-bottom: 0px;
}

.alert-button-inner.sc-ion-alert-ios {
  justify-content: center;
}

.ios button.alert-button.alert-button-confirm {
  border-radius: 12px;
  text-align: center;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
  border: none;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}
