@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
/* @import '@ionic/angular/css/palettes/dark.system.css'; */

@import '@angular/cdk/overlay-prebuilt.css';
@import './theme/cdk-overlay.scss';

@import './theme/global.scss';
@import './theme/variables.scss';
@import './theme/font.scss';
@import './theme/toastr.scss';
@import './theme/typography.scss';
@import './theme/ion-toast.scss';
@import './theme/ion-skeleton.scss';
@import './theme/ion-modal.scss';
@import './theme//angular-material.scss';
@import './theme/ion-alert.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar for IE, Edge and Firefox
ion-content::part(scroll) {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}

// 커스텀 scroll
// ion-content::part(scroll)::-webkit-scrollbar {
//   width: 8px !important;
//   height: 8px !important;
// }

// ion-content::part(scroll)::-webkit-scrollbar-track {
//   @apply bg-gray-400;
// }

// ion-content::part(scroll)::-webkit-scrollbar-track:hover {
//   @apply bg-gray-400;
// }

// ion-content::part(scroll)::-webkit-scrollbar-thumb {
//   @apply bg-primary rounded-lg;
// }

// ion-content::part(scroll)::-webkit-scrollbar-thumb:hover {
//   @apply bg-primary rounded-lg;
// }
